.container {
  justify-items: center;
  padding: 40px;
  margin-top: 50px;
  margin-left: -10px;
  margin-right: -10px;
}

.nav {
  right: 5px;
  float: right;
  position: fixed;
  width: -1000px;
  z-index: 1;
}
.name {
  display: inline-block;

  position: fixed;
  padding-left: 3%;
}

.header {
  position: fixed;
  z-index: 1;

  background-color: rgba(255, 255, 255, 0.979);
  width: 100%;
  height: 130px;
  top: 0px;
  left: 0px;
}
#descript {
  text-align: center;
  align-items: center;
  height: 400px;
}
h1 {
  font-family: "work sans", sans-serif;

  font-weight: 400;

  font-style: normal;
}
h5 {
  font-family: "work sans", sans-serif;

  font-weight: 400;

  font-style: normal;
}
h6 {
  font-family: "work sans", sans-serif;

  font-weight: 400;

  font-style: normal;
}
#ab {
  background-color: rgb(252, 243, 243);
  
}

