.work {
  padding: 20px;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.grid-item {
  position: relative;
  text-align: center;
}

.grid-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.hover-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
}

.hover-info h3, .hover-info h6 {
  margin: 0;
}
h1{
  text-align: center;
  padding-top: 5%;
}